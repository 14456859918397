import React, { useState } from 'react'
import '../Styles/bdayjoke.css'

export default function BDayJoke({
    seconds, setSeconds,
minutes, setMinutes,
hours, setHours,
days, setDays, timeNow, goalDate, timeToGo
}) {

    const secAmmout = 1000
    const minAmmount = secAmmout * 60
    const hourAmmount = minAmmount * 60
    const dayAmmount = hourAmmount * 24

    const daysLeft = (timeToGo / dayAmmount)
    setDays(Math.floor(daysLeft))

    const hoursLeft   = (timeToGo - (Math.floor(daysLeft) * dayAmmount))/hourAmmount
    setHours(Math.floor(hoursLeft))

    const minutesLeft = (timeToGo - 
        (Math.floor(daysLeft) * dayAmmount + 
        Math.floor(hoursLeft) * hourAmmount))
        /minAmmount
    setMinutes(Math.floor(minutesLeft))

    const secondsLeft = (timeToGo - 
        (Math.floor(daysLeft) * dayAmmount + 
        Math.floor(hoursLeft) * hourAmmount + 
        Math.floor(minutesLeft) * minAmmount))
        /secAmmout
    setSeconds(Math.floor(secondsLeft))
    

    return (
        <div className="AppDiv">
            <h1>Time until the New Minecraft Season starts: </h1>
            <div className="something">
            {days > 0 ? <p>Days:
            {days}</p>: null}
            {hours > 0 ? <p>Hours:
            {hours}</p>: null}
            {minutes > 0 ? <p>Minutes:
            {minutes}</p>: null}
            {seconds > 0 ? <p>Seconds:
            {seconds}</p>: null}
            </div>
        </div>
    )
}
  
