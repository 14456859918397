import React, { useState } from 'react';
import './App.css';
import BDayJoke from './Components/BDayJoke';
import Celebrate from './Components/Celebrate';

function AppJoke() {
    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [hours, setHours] = useState(0)
    const [days, setDays] = useState(0)
    const [timeNow, setTimeNow] = useState(new Date())
    const goalDate = new Date(2021, 8, 9, 19, 30)
    const timeToGo = goalDate - timeNow
    setInterval(() => {
        setTimeNow(new Date())
    }, 1000);
    return timeToGo > 0 ? <BDayJoke 
            seconds = {seconds}
            minutes = {minutes}
            hours = {hours}
            days = {days}
              setSeconds = {setSeconds}
              setMinutes = {setMinutes}
              setHours = {setHours}
              setDays = {setDays}
              timeNow ={ timeNow}
              goalDate = {goalDate}
              timeToGo = {timeToGo}
            />: <Celebrate />
}

export default AppJoke;
